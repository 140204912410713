import React from 'react';
import { observer } from 'mobx-react-lite';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { Tooltip } from 'polotno/canvas/tooltip';
// import { PageControls } from 'polotno/canvas/page-controls';
import { useAuth0 } from '@auth0/auth0-react';

import { loadFile } from './file';
// import { QrSection } from './sections/qr-section';
// import { ThenounprojectSection } from './thenounproject-section';
// import { QuotesSection } from './sections/quotes-section';
// import { IconsSection } from './sections/icons-section';
// import { IllustrationsSection } from './sections/illustrations-section';
// import { ShapesSection } from './sections/shapes-section';

import { StableDiffusionSection } from './sections/stable-diffusion-section';
import { useProject } from './project';

import { useInfiniteAPI } from 'polotno/utils/use-api';


//----------------
import { InputGroup } from '@blueprintjs/core';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { getImageSize } from 'polotno/utils/image';
import { SectionTab } from 'polotno/side-panel';

import MdPhotoLibrary from '@meronex/icons/md/MdPhotoLibrary';
import BsTextCenter from '@meronex/icons/bs/BsTextCenter';
import FcImageFile from '@meronex/icons/fc/FcImageFile';

//---------------- custom sizes

import { GiResize } from 'react-icons/gi';
import { Button } from '@blueprintjs/core';
//----------------


import {
  TextSection,
  ElementsSection,
  UploadSection,
  LayersSection,
  // BackgroundSection,
  SizeSection,
} from 'polotno/side-panel';

//----------------


// import fr from './translations/fr';
// import en from './translations/en';
// import id from './translations/id';
// import ru from './translations/ru';
// import ptBr from './translations/pt-br';

import Topbar from './topbar/topbar';
import {getKey} from "polotno/utils/validate-key";


var last_background_url = '';


//---------------- bcz templates panel
export const BczTemplatesPanel = observer(({ store }) => {
  // load data
  const { data, isLoading, loadMore } = useInfiniteAPI({
    getAPI: ({ page }) => `https://studio.bookcoverzone.com/get-text-templates.php?page=${page}`,
  });
  
  return (
    <div style={{ height: '100%' }}>
      <ImagesGrid
        shadowEnabled={true}
        images={data?.map((data) => data.items).flat()}
        getPreview={(item) => `${item.preview}`}
        isLoading={isLoading}
        onSelect={async (item) => {
          // download selected json
          const req = await fetch(`${item.json}`);
          const json = await req.json();
          // just inject it into store
          store.loadJSON(json);
          
          store.deleteElements(['bookcover_background']);
          
          if (last_background_url !== '') {
            let page_width = store.activePage.computedWidth;
            let page_height = store.activePage.computedHeight;
            
            store.activePage.addElement({
              type: 'image',
              src: last_background_url,
              id: 'bookcover_background',
              locked: true,
              width: page_width,
              height: page_height,
              // if position is available, show image on dropped place
              // or just show it in the center
              x: 0, //pos ? pos.x : store.width / 2 - width / 2,
              y: 0, //pos ? pos.y : store.height / 2 - height / 2,
            });
            store.getElementById('bookcover_background').moveBottom();
          }
          
        }}
        rowsNumber={2}
        loadMore={loadMore}
      />
    </div>
  );
});

// define the new bcz templates section
export const BczTemplatesSection = {
  name: 'bcz-templates',
  Tab: (props) => (
    <SectionTab name="Layouts" {...props}>
      <BsTextCenter />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: BczTemplatesPanel,
};


//---------------- bcz panel begin

export const BczCoversPanel = observer(({ store }) => {
  
  const { data, isLoading, loadMore } = useInfiniteAPI({
    getAPI: ({ page }) => `https://studio.bookcoverzone.com/get-covers.php?page=${page}`,
  });
  
  
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <InputGroup
        leftIcon="search"
        placeholder="Search..."
        onChange={(e) => {
        }}
        style={{
          marginBottom: '20px',
        }}
      />
      <p>SoldOnce Covers Placeholder: </p>
      {/* you can create yur own custom component here */}
      {/* but we will use built-in grid component */}
      <ImagesGrid
        images={data?.map((data) => data.items).flat()}
        getPreview={(item) => `${item.preview}`}
        isLoading={isLoading}
        onSelect={async (item, pos) => {
          const { width, height } = await getImageSize(item.url);
          console.log(width,height);
          
          let page_width = store.activePage.computedWidth;
          let page_height = store.activePage.computedHeight;
          console.log(page_width, page_height);
          
          last_background_url = item.url;
          
          //delete existing bookcover_background
          const element = store.getElementById('bookcover_background');
        
          store.deleteElements(['bookcover_background']);
          
          store.activePage.addElement({
            type: 'image',
            src: item.url,
            id: 'bookcover_background',
            locked: true,
            width: page_width,
            height: page_height,
            // if position is available, show image on dropped place
            // or just show it in the center
            x: 0, //pos ? pos.x : store.width / 2 - width / 2,
            y: 0, //pos ? pos.y : store.height / 2 - height / 2,
          });
          store.getElementById('bookcover_background').moveBottom();
        }}
        rowsNumber={2}
        loadMore={loadMore}
      />
    </div>
  );
});

// define the new custom section
const BczCoversSection = {
  name: 'bcz',
  Tab: (props) => (
    <SectionTab name="Covers" {...props}>
      <FcImageFile />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: BczCoversPanel,
};

//---------------- bcz panel end

//------------------- custom sizes begin

const AVAILABLE_SIZES = [
  { display_name: 'Kindle', width: 1600, height: 2560 },
  { display_name: '5.00x8.00', width: 1540, height: 2475 },
  { display_name: '5.25x8.00', width: 1615, height: 2475 },
  { display_name: '5.50x8.50', width: 1690, height: 2625 },
  { display_name: '6.00x9.00',width: 1840, height: 2775 },
  { display_name: '6.14x9.21',width: 1882, height: 2838 },
  { display_name: '6.69x9.61',width: 2048, height: 2958 },
];

// define the new custom section
const CustomSizesPanel = {
  name: 'sizes',
  Tab: (props) => (
    <SectionTab name="Trim" {...props}>
      <GiResize icon="new-text-box" />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store   }) => {
    // we will just render buttons for each size
    // but you also can add your own controls
    // size inputs, etc
    return (
      <div>
        {AVAILABLE_SIZES.map(({ display_name, width, height }, i) => (
          <Button
            key={i}
            style={{ width: '100%', marginBottom: '20px' }}
            onClick={() => {
              console.log(width+' '+height);
              console.log(store.width+' '+store.height);
              console.log(store.pages[0]);
              // store.setSize(width, height, true);
              store.pages[0].setSize({
                width: width,
                height: height,
                useMagic: true,
              });
            }}
          >
            {display_name} - {width}x{height}px
          </Button>
        ))}
      </div>
    );
  }),
};



//------------------- custom sized end



const new_sections = [
  BczTemplatesSection,
  BczCoversSection,
  CustomSizesPanel,
  TextSection,
  ElementsSection,
  UploadSection,
  // StableDiffusionSection,
  // IconsSection,
  // BackgroundSection,
  // we will replace default resize with our own
  // SizeSection,
  // IllustrationsSection,
  // ShapesSection,
  LayersSection,
];

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const App = observer(({ store }) => {
  const project = useProject();
  const height = useHeight();
  
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  const load = () => {
    let url = new URL(window.location.href);
    // url example https://studio.polotno.com/design/5f9f1b0b
    const reg = new RegExp('design/([a-zA-Z0-9_-]+)').exec(url.pathname);
    const designId = (reg && reg[1]) || 'local';
    project.loadById(designId);
  };
  
  
  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          project.authToken = token;
          load();
        })
        .catch((err) => {
          project.authToken = null;
          load();
          console.log(err);
        });
    } else {
      project.authToken = null;
      load();
    }
  }, [isAuthenticated, project, getAccessTokenSilently, isLoading]);

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  return (
    <div
      style={{
        width: '100vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onDrop={handleDrop}
    >
      <Topbar store={store} />
      <div style={{ height: 'calc(100% - 50px)' }}>
        <PolotnoContainer className="polotno-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={new_sections} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar
              store={store}
              components={{
              }}
            />
            <Workspace store={store}  components={{ PageControls: () => null, Tooltip }} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </div>
    </div>
  );
});

export default App;
